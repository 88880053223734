<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <div class="verify-div">
        <FuNav
          :fixed="false"
          :title="$t('ebClaim.TrackingClaim')"
          :arrow="true"
          :back="() => {$router.push({name: 'EbClaimPortal'})}"
          percent
        />
        <div class="img-dev">
          <img class="img1" :src="portal27">
          <p class="verify-t">{{ $t('ebClaim.TrackingPageTitle') }}</p>
          <p class="verify-s">{{ $t('ebClaim.VerificationCodeText') }}</p>
        </div>

        <FuFormMin
          ref="FuForm"
          v-model="formData"
          class="p10"
          :schema="formColumns"
          :lang="lang"
        />

        <div class="btn-group">
          <div class="verify-btn-group">
            <Button
              class="fu-v2-btn danger block"
              round
              block
              type="danger"
              @click="handleNext"
            >{{ $t('ebClaim.Next') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import FuFormMin from '@/components/FuFormMin'
import { Button } from 'vant'
import portal27 from '@/assets/imgs/portal27.png'
import { getFormV2Columns } from './config/index.js'
import { sendOtp } from '@/views/claim/api/index.js'
import { toastLoading } from '@/utils'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { FuFormMin, Button, FuNav },
  props: {},
  data() {
    return {
      portal27,
      formData: {},
      formColumns: getFormV2Columns()
    }
  },
  computed: {
    lang() {
      return this.$store.state.base.lang
    }
  },
  activated() {
    this.init()
  },
  methods: {
    init() {
      this.formColumns = getFormV2Columns()
    },
    formValid() {
      return new Promise((resolve) => {
        this.$refs.FuForm.validate().then(res => {
          resolve(true)
        }).catch(() => {
          resolve(false) // TODO: 此处需要修改
        })
      })
    },
    async handleNext() {
      const valid = await this.formValid()
      if (!valid) return

      const { identityNo: employeeIdCard, phone: employeePhone } = this.formData.shareDatas

      this.loading = toastLoading()
      const res = await sendOtp({ bizType: 2, employeeIdCard, employeePhone })
      this.loading && this.loading.clear()
      this.loading = null
      if (res.sent) {
        this.$router.push({ name: 'VerifyCode', query: {
          bizType: 2,
          employeeIdCard,
          employeePhone
        }})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.verify-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 28px;
  text-align: center;
}
.img1 {
  width: 282px;
}
.verify-t {
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px;
  text-align: center;
}
.btn-group {
  box-shadow: 4px 0px 30px 0px #0000001A;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  .verify-btn-group {
    display: flex;
    gap: 10px;
  }
}
::v-deep .van-password-input__security li {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.verify-agree {
  display: flex;
  padding: 10px;
  box-shadow: 0px 4px 20px 0px #0000001A;
  margin-bottom: 10px;
  gap: 20px;
  .verify-agree-tc {
    color: #15B9FF;

  }
}
</style>
